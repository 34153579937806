<template>
  <upset-js
    :sets="sets"
    :width="width"
    :height="height"
    @hover="hover"
    :selection="selection"
    selectionColor="#1896E1"
  ></upset-js>
</template>

<script>
import UpSetJS, { extractSets } from '@upsetjs/vue'

export default {
  name: 'UpsetJs',
  components: {
    'upset-js': UpSetJS
  },
  props: {
    data: {
      type: Object,
      default: null
    }
  },
  data: function () {
    return {
      width: 0,
      height: 600,
      selection: null
    }
  },
  computed: {
    sets () {
      return extractSets(this.data.data)
    }
  },
  mounted () {
    const bb = this.$el.getBoundingClientRect()
    this.width = bb.width
    this.height = bb.height
  },
  methods: {
    hover (s) {
      this.selection = s
    }
  }
}
</script>
